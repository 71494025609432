<template>

  <b-card no-body>
    <TaskAdd :is-task-new-sidebar-active.sync="isTaskNewSidebarActive" :record-data-id="taskRecordDataId"></TaskAdd>

    <div class="m-1">
      <b-row>
        <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
          <v-select v-model="perPage" class="per-page-selector d-inline-block mx-50" :options="perPageOptions" :clearable="false"/>

          <b-badge pill :variant="`light-warning`" class="text-capitalize" v-if="taskRecordTypeList === 'OUTGOING' && taskStatusTypeList === 'WAITING'">
            Giden - Bekleyen Görev Listesi
          </b-badge>

          <b-badge pill :variant="`light-warning`" class="text-capitalize" v-if="taskRecordTypeList === 'OUTGOING' && taskStatusTypeList === 'ANSWERED'">
            Giden - Cevaplanmış Görev Listesi
          </b-badge>

          <b-badge pill :variant="`light-warning`" class="text-capitalize" v-if="taskRecordTypeList === 'OUTGOING' && taskStatusTypeList === 'CLOSED'">
            Giden - Kapanmış Görev Listesi
          </b-badge>

          <b-badge pill :variant="`light-warning`" class="text-capitalize" v-if="taskRecordTypeList === 'INCOMING' && taskStatusTypeList === 'WAITING'">
            Gelen - Bekleyen Görev Listesi
          </b-badge>

          <b-badge pill :variant="`light-warning`" class="text-capitalize" v-if="taskRecordTypeList === 'INCOMING' && taskStatusTypeList === 'ANSWERED'">
            Gelen - Cevaplanmış Görev Listesi
          </b-badge>

          <b-badge pill :variant="`light-warning`" class="text-capitalize" v-if="taskRecordTypeList === 'INCOMING' && taskStatusTypeList === 'CLOSED'">
            Gelen - Kapanmış Görev Listesi
          </b-badge>

        </b-col>
        <b-col cols="12" md="6">
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Arama..."/>
            <b-button-toolbar justify>
              <b-button-group>
                <b-button @click="reFetchData" variant="outline-primary" size="md">
                  <feather-icon icon="RefreshCcwIcon" size="18"/>
                  <span class="align-middle"></span>
                </b-button>
                <b-button @click="isTaskNewSidebarActive = true; taskRecordDataId=0" variant="primary" size="md">
                  <feather-icon icon="PlusCircleIcon" size="18"/>
                  <span class="align-middle"></span>
                </b-button>
              </b-button-group>
            </b-button-toolbar>
          </div>
        </b-col>
      </b-row>
    </div>

    <b-table
        ref="refListTable"
        primary-key="id"
        empty-text="Kayıt bulunamadı"
        :class="`position-relative table-sticky ${totalCount > 0 && totalCount < 5 ? 'dropdown-h150': ''}`"
        :items="fetchList"
        :fields="tableColumns"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        responsive striped bordered hover show-empty>

      <template #cell(id)="data">
        <span class="text-nowrap">
              <b-link :to="{ name: 'task-detail', params: { id: data.value, recordType: taskRecordTypeList }}" class="font-weight-bold">
                  #{{ data.value }}
              </b-link>
        </span>
      </template>

      <template #cell(taskNo)="data">
        <div class="text-nowrap">{{ data.value }}</div>
      </template>

      <template #cell(createdOn)="data">
        <date-time-column-table :data="data.value"></date-time-column-table>
      </template>

      <template #cell(taskTypeName)="data">
        <div class="text-nowrap">{{ data.value }}</div>
      </template>

      <template #cell(createdUserPicture)="data">
        <div class="text-nowrap">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                  size="32"
                  :src="getApiFile(data.value)"
                  :text="avatarText(data.item.createdUserName)"
                  :variant="`light-primary`"/>
            </template>
            <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.createdUserName }}
          </span>
            <small class="text-muted">{{ data.item.createdUserPositionName }}</small>
          </b-media>
        </div>
      </template>

      <template #cell(taskDefinitionName)="data">
        <div class="text-nowrap">{{ data.value }}</div>
      </template>

      <template #cell(organizationDepartmentName)="data">
        <div class="text-nowrap">{{ data.value }}</div>
      </template>

      <template #cell(organizationUnitName)="data">
        <div class="text-nowrap">{{ data.value }}</div>
      </template>

      <template #cell(taskCategoryName)="data">
        <div class="text-nowrap">{{ data.value }}</div>
      </template>

      <template #cell(taskJobType)="data">
        <div class="text-nowrap">
          <b-badge pill :variant="`light-success`" class="text-capitalize" v-if="data.item.taggedUser === false && data.value === 'PRIMARY'">
            Birincil
          </b-badge>
          <b-badge pill :variant="`light-secondary`" class="text-capitalize" v-if="data.item.taggedUser === false && data.value === 'SECONDARY'">
            İkincil
          </b-badge>
          <b-badge pill :variant="`light-warning`" class="text-capitalize" v-if="data.item.taggedUser">
            Bilgilendirme
          </b-badge>
        </div>
      </template>

      <template #cell(taskPriorityStatusName)="data">
        <b-badge pill :variant="`light-${recordTaskPriorityStatusVariant(data.item)}`" class="text-capitalize">
          {{ data.value }}
        </b-badge>
      </template>

      <template #cell(processingUserPicture)="data">
        <div class="text-nowrap">
          <b-media vertical-align="center" v-if="data.item.processingUserId !== null">
            <template #aside>
              <b-avatar
                  size="32"
                  :src="getApiFile(data.value)"
                  :text="avatarText(data.item.processingUserName)"
                  :variant="`light-primary`"/>
            </template>
            <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.processingUserName }}
          </span>
            <small class="text-muted">{{ data.item.processingUserPositionName }}</small>
          </b-media>
        </div>
      </template>

      <template #cell(taskStatusName)="data">
        <b-badge pill :variant="`light-${recordTaskStatusVariant(data.item)}`" class="text-capitalize">
          {{ data.value }}
        </b-badge>
      </template>

      <template #cell(actions)="data">
        <div class="text-nowrap">
          <b-dropdown text="Dropdown" variant="link" no-caret toggle-class="p-0" dropleft v-if="!(taskRecordTypeList === 'OUTGOING' && (taskStatusTypeList === 'ANSWERED' || taskStatusTypeList === 'CLOSED')) && !(taskRecordTypeList === 'INCOMING' && taskStatusTypeList === 'CLOSED')">
            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body"/>
            </template>
            <b-dropdown-item @click="isTaskNewSidebarActive = true; taskRecordDataId=data.item.id" v-if="taskRecordTypeList === 'OUTGOING'">
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">Değiştir</span>
            </b-dropdown-item>
            <b-dropdown-item @click="taskProcessing(data.item)" v-if="!(taskRecordTypeList === 'OUTGOING' && taskStatusTypeList === 'WAITING')">
              <feather-icon icon="ActivityIcon"/>
              <span class="align-middle ml-50" v-if="data.item.processingUserId === null">Görevi Al</span>
              <span class="align-middle ml-50" v-if="data.item.processingUserId !== null">Görevi Bırak</span>
            </b-dropdown-item>
            <b-dropdown-item @click="taskClosed(data.item)" v-if="!((taskRecordTypeList === 'OUTGOING' || taskRecordTypeList === 'INCOMING') && taskStatusTypeList === 'WAITING') && !(taskRecordTypeList === 'INCOMING' && taskStatusTypeList === 'ANSWERED')">
              <feather-icon icon="CheckSquareIcon"/>
              <span class="align-middle ml-50">Görevi Kapat</span>
            </b-dropdown-item>
            <b-dropdown-item @click="redirectShowModal(data.item)" v-if="!(taskRecordTypeList === 'OUTGOING' && taskStatusTypeList === 'WAITING') && !(taskRecordTypeList === 'INCOMING' && taskStatusTypeList === 'ANSWERED')">
              <feather-icon icon="SendIcon"/>
              <span class="align-middle ml-50">Görevi Yönlendir</span>
            </b-dropdown-item>
            <b-dropdown-item @click="cancelShowModal(data.item)" v-if="!(taskRecordTypeList === 'INCOMING' && taskStatusTypeList === 'WAITING') && !(taskRecordTypeList === 'INCOMING' && taskStatusTypeList === 'ANSWERED')">
              <feather-icon icon="XSquareIcon"/>
              <span class="align-middle ml-50">Görevi İptal Et</span>
            </b-dropdown-item>
            <remove-button-table :data="data" :re-fetch-data="reFetchData" :dropdown="true" v-if="taskRecordTypeList === 'OUTGOING'"></remove-button-table>
          </b-dropdown>
          <feather-icon :id="`row-${data.item.id}-prev`" icon="EyeIcon" size="16" @click="$router.push({name: 'task-detail', params: {id: data.item.id, recordType: taskRecordTypeList}})" class="mx-1"/>
          <b-tooltip title="Görüntüle" class="cursor-pointer" :target="`row-${data.item.id}-prev`"/>
        </div>
      </template>

    </b-table>

    <div class="mx-2 mb-2">
      <b-row>
        <footer-table :data-meta="dataMeta"></footer-table>
        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
          <b-pagination class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item" v-model="currentPage" :total-rows="totalCount" :per-page="perPage" first-number last-number>
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18"/>
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18"/>
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>

    <Overlay :busy="busy"></Overlay>

    <b-modal v-model="redirectModalShow" ref="redirect-modal" title="Yönlendir" ok-title="Kaydet" cancel-title="Vazgeç" cancel-variant="outline-secondary" @ok="handleRedirectModal" centered no-close-on-backdrop no-close-on-esc>
      <b-form @submit.stop.prevent="submitRedirectModal" ref="refFormObserver">
        <b-form-group label="* Birim">
          <v-select v-model="redirectInfo.organizationUnitId" :options="organizationUnitOptions" :reduce="val => val.value" :clearable="false" v-on:input="getTaskCategories"/>
        </b-form-group>
        <b-form-group label="* Kategori">
          <v-select v-model="redirectInfo.taskCategoryId" :options="taskCategoryOptions" :reduce="val => val.value" :clearable="false"/>
        </b-form-group>
      </b-form>
    </b-modal>

    <b-modal v-model="cancelModalShow" ref="cancel-modal" title="İptal Et" ok-title="Kaydet" cancel-title="Vazgeç" cancel-variant="outline-secondary" @ok="handleCancelModal" centered no-close-on-backdrop no-close-on-esc>
      <b-form @submit.stop.prevent="submitCancelModal" ref="refFormObserver">
        <b-form-group label="* İptal Açıklaması">
          <b-form-textarea trim placeholder="Açıklama" v-model="cancelInfo.description" rows="3"/>
        </b-form-group>
      </b-form>
    </b-modal>

  </b-card>

</template>

<script>
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import store from '@/store'
import useList from './useList'
import storeModule from './store'
import taskCategoryModule from "@/views/definition/taskcategory/store";
import organizationModule from "@/views/organization/organization/store"
import Overlay from "@/components/Overlay.vue"
import {avatarText, getApiFile, recordTaskStatusVariant, recordTaskPriorityStatusVariant} from "@core/utils/filter"
import Vue from "vue"
import {HeaderTable, IdColumnTable, DateTimeColumnTable, RemoveButtonTable, FooterTable} from "@/components/Table";
import {BAvatar, BBadge, BButton, BButtonGroup, BButtonToolbar, BCard, BCol, BDropdown, BDropdownItem, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BFormTextarea, BLink, BMedia, BModal, BPagination, BRow, BTable, BTooltip} from "bootstrap-vue";
import TaskAdd from "@/views/task/Add.vue";
import router from "@/router";
import flatpickr from "vue-flatpickr-component/src/component";
import {toastMessage} from "@core/utils/utils";
import {useToast} from "vue-toastification/composition";

export default {
  components: {
    BFormTextarea,
    BFormInvalidFeedback,
    BModal,
    BForm,
    BFormGroup,
    BButton,
    BButtonToolbar,
    BButtonGroup,
    BLink,
    BMedia,
    BDropdownItem,
    BBadge,
    BDropdown,
    BPagination,
    BTooltip,
    BAvatar,
    BTable,
    BCard,
    BRow,
    BCol,
    BFormInput,

    flatpickr,
    IdColumnTable,
    RemoveButtonTable,
    DateTimeColumnTable,
    FooterTable,
    HeaderTable,
    vSelect,
    Overlay,
    TaskAdd,
  },
  setup() {
    const STORE_MODULE_NAME = 'store'
    const STORE_ORGANIZATION_MODULE_NAME = 'store-organization'
    const STORE_TASK_CATEGORY_MODULE_NAME = 'store-task-category'

    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, storeModule)
      store.registerModule(STORE_ORGANIZATION_MODULE_NAME, organizationModule)
      store.registerModule(STORE_TASK_CATEGORY_MODULE_NAME, taskCategoryModule)
    }
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) {
        store.unregisterModule(STORE_MODULE_NAME)
        store.unregisterModule(STORE_ORGANIZATION_MODULE_NAME)
        store.unregisterModule(STORE_TASK_CATEGORY_MODULE_NAME)
      }
    })

    const toast = useToast()

    const {
      tableColumns,
      perPage,
      currentPage,
      totalCount,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      busy,

      fetchList,
      reFetchData,
    } = useList()

    const taskProcessing = (dataItem) => {
      Vue.swal({
        title: 'Onaylıyor musunuz?',
        text: dataItem.processingUserId === null ? 'Kayıt işleme alınacaktır!' : 'Görevi bırak işlemi yapılacaktır!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet, İşlemi Yap',
        cancelButtonText: 'Vazgeç',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('store/processingItem', {id: dataItem.id}).then(response => {
            if (response.data.success) {
              router.push({name: 'task-detail', params: {id: dataItem.id, recordType: taskRecordTypeList}})
            }
          }).catch(error => {
            Vue.swal({
              icon: 'error',
              title: 'Hata!',
              text: error.response.data.message,
              confirmButtonText: 'Tamam',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            })
          })
        }
      })
    }
    const taskClosed = (dataItem) => {
      Vue.swal({
        title: 'Onaylıyor musunuz?',
        text: 'Kayıt kapatılacaktır!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet, Kapat',
        cancelButtonText: 'Vazgeç',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('store/closedItem', {id: dataItem.id}).then(response => {
            if (response.data.success) {
              reFetchData()
              Vue.swal({
                icon: 'success',
                title: 'Başarılı!',
                text: 'Kayıt kapatıldı.',
                confirmButtonText: 'Tamam',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
              })
            }
          }).catch(error => {
            Vue.swal({
              icon: 'error',
              title: 'Hata!',
              text: error.response.data.message,
              confirmButtonText: 'Tamam',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            })
          })
        }
      })
    }

    const redirectInfo = ref({
      taskId: null,
      organizationUnitId: null,
      taskCategoryId: null,
      description: ''
    })

    const isTaskNewSidebarActive = ref(false)
    const taskRecordDataId = ref(0)

    const taskRecordTypeList = router.currentRoute.params.recordType
    const taskStatusTypeList = router.currentRoute.params.statusType

    const organizationUnitOptions = ref([])
    const taskCategoryOptions = ref([])
    const redirectModalShow = ref(false)
    const redirectShowModal = (dataItem) => {
      if (dataItem.taskStatusId === 'WAITING' || dataItem.taskStatusId === 'PROCESSING' || dataItem.taskStatusId === 'ANSWERED' || dataItem.taskStatusId === 'REDIRECTED') {
        redirectInfo.value = {
          taskId: dataItem.id,
          organizationUnitId: null,
          taskCategoryId: null,
          description: ''
        }

        //get units
        organizationUnitOptions.value = []
        busy.value = true
        store.dispatch('store-organization/fetchDepartmentUnits', {departmentId: dataItem.organizationDepartmentId}).then(response => {
          response.data.data.forEach((value, index) => {
            if (value.isActive && value.id !== dataItem.organizationUnitId) {
              organizationUnitOptions.value.push({label: value.name, value: value.id})
            }
          });
          redirectModalShow.value = true
        }).catch(error => {
          toastMessage(toast, 'danger', error.response.data.message)
        }).finally(message => {
          busy.value = false
        })

      } else {
        Vue.swal({
          icon: 'error',
          title: 'Hata!',
          text: 'Görev durumu uygun değil!',
          confirmButtonText: 'Tamam',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      }
    }
    const handleRedirectModal = (bvModalEvt) => {
      bvModalEvt.preventDefault()
      submitRedirectModal()
    }
    const submitRedirectModal = () => {

      if (redirectInfo.value.organizationUnitId === null) {
        Vue.swal({
          icon: 'error',
          title: 'Hata!',
          text: 'Birim seçimi yapılmalıdır!',
          confirmButtonText: 'Tamam',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })

        return
      }

      if (redirectInfo.value.taskCategoryId === null) {
        Vue.swal({
          icon: 'error',
          title: 'Hata!',
          text: 'Kategori seçimi yapılmalıdır!',
          confirmButtonText: 'Tamam',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })

        return
      }

      busy.value = true
      store.dispatch('store/redirectItem', redirectInfo.value).then(response => {
        reFetchData()
        Vue.swal({
          icon: 'success',
          title: 'Başarılı!',
          text: 'Görev birime yönlendirildi.',
          confirmButtonText: 'Tamam',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      }).catch(error => {
        Vue.swal({
          icon: 'error',
          title: 'Hata!',
          text: error.response.data.message,
          confirmButtonText: 'Tamam',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      }).finally(message => {
        busy.value = false
      })

      redirectInfo.value = {
        taskId: null,
        organizationUnitId: null,
        taskCategoryId: null,
        description: ''
      }
      redirectModalShow.value = false
    }
    const getTaskCategories = (val) => {
      taskCategoryOptions.value = []
      busy.value = true
      store.dispatch('store-task-category/fetchItems', {organizationUnitId: val}).then(response => {
        response.data.data.forEach((value, index) => {
          if (value.isActive) {
            taskCategoryOptions.value.push({label: value.name, value: value.id})
          }
        });
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.message)
      }).finally(message => {
        busy.value = false
      })
    }

    const cancelInfo = ref({
      taskId: null,
      description: ''
    })
    const cancelModalShow = ref(false)
    const cancelShowModal = (dataItem) => {
      if (dataItem.taskStatusId === 'WAITING') {
        cancelInfo.value = {
          taskId: dataItem.id,
          description: ''
        }

        cancelModalShow.value = true
      } else {
        Vue.swal({
          icon: 'error',
          title: 'Hata!',
          text: 'Görev durumu uygun değil!',
          confirmButtonText: 'Tamam',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      }
    }
    const handleCancelModal = (bvModalEvt) => {
      bvModalEvt.preventDefault()
      submitCancelModal()
    }
    const submitCancelModal = () => {

      if (cancelInfo.value.description === null || cancelInfo.value.description === '') {
        Vue.swal({
          icon: 'error',
          title: 'Hata!',
          text: 'İptal açıklaması girilmelidir!',
          confirmButtonText: 'Tamam',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })

        return
      }

      busy.value = true
      store.dispatch('store/cancelItem', cancelInfo.value).then(response => {
        reFetchData()
        Vue.swal({
          icon: 'success',
          title: 'Başarılı!',
          text: 'Görev iptal edildi.',
          confirmButtonText: 'Tamam',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      }).catch(error => {
        Vue.swal({
          icon: 'error',
          title: 'Hata!',
          text: error.response.data.message,
          confirmButtonText: 'Tamam',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      }).finally(message => {
        busy.value = false
      })

      cancelInfo.value = {
        taskId: null,
        description: ''
      }
      cancelModalShow.value = false
    }

    return {
      tableColumns,
      perPage,
      currentPage,
      totalCount,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      busy,
      isTaskNewSidebarActive,
      taskRecordDataId,
      taskRecordTypeList,
      taskStatusTypeList,
      organizationUnitOptions,
      taskCategoryOptions,
      redirectModalShow,
      redirectInfo,
      cancelInfo,
      cancelModalShow,

      redirectShowModal,
      handleRedirectModal,
      submitRedirectModal,
      getTaskCategories,

      cancelShowModal,
      handleCancelModal,
      submitCancelModal,

      fetchList,
      reFetchData,
      getApiFile,
      avatarText,
      recordTaskStatusVariant,
      recordTaskPriorityStatusVariant,
      taskProcessing,
      taskClosed,
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select';
</style>
